<template>
    <!-- 运维管理》承载量 -->
    <div id="app_operation_cheng_zai_liang_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">承载量</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
             <!-- 查询条件 -->
            <el-row id="query_condition_id" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" >
                    
                    <el-form-item label="开始日期">
                        <el-date-picker
                            v-model="form_data.startDate"
                            type="date"
                            placeholder="选择日期" size="mini" style="width:130px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="结束日期">
                        <el-date-picker
                            v-model="form_data.endDate"
                            type="date"
                            placeholder="选择日期" size="mini" style="width:130px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>

                </el-form>
                
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-form_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  prop="ip" label="IP" min-width="100" fixed align="center"> </el-table-column>
                    <el-table-column  prop="num" label="发送总量" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="cmpp2_0" label="cmpp2.0" min-width="100"  align="center">  </el-table-column>
                    <el-table-column  prop="cmpp3_0" label="cmpp3.0" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="SGIP1_0" label="SGIP1.0" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="SGIP1_2" label="SGIP1.2" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="SMGP" label="SMGP" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="http1_0" label="http1.0" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="http2_0" label="http2.0" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="http" label="Http助通" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="no" label="无相关协议" min-width="100"  align="center"> </el-table-column>

                </el-table>
            </el-row>

        </el-row>

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

//import axios from "axios";
export default {
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{
                startDate:new Date(),//查询条件-开始日期
                endDate:new Date(),//查询条件-结束日期
            },
            
            tableData:[],//表格数据
            
            form_height:40,//查询条件的高度

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.companyList = this.$options.data().companyList;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
        //初始化设置
        this.initSetUp();
        //查询
        //this.submit_cha_xun();
       
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.$nextTick(function(){
                //获取分页的高度
                if(document.getElementById('query_condition_id')){
                    this.form_height = document.getElementById('query_condition_id').clientHeight;
                    console.log("form_height:"+this.form_height);
                }
            });

        },

       

        //查询
        submit_cha_xun(){

            if(API.isEmtry(this.form_data.startDate)){
                this.$message.error("请选择开始日期");
                return;
            }
            if(API.isEmtry(this.form_data.endDate)){
                this.$message.error("请选择结束日期");
                return;
            }

            var startDate = dateFtt(this.form_data.startDate,"yyyy-MM-dd");
            var endDate = dateFtt(this.form_data.endDate,"yyyy-MM-dd");
            //请求接口
            API.OperationManagerServlet({
                param: "queryList",
                startDate:startDate,
                endDate:endDate,

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.tableData = res.list;
                    
                } 
            });
        },
        

        //返回
        goBack() {
            API.router_to("/operation_index");
        }
    }
};

</script>

<style scoped>


</style>